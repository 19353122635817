




































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { NouveauSansDossierCreateModel } from "@/api/models/dossiers/dossier";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { Entreprise } from "@/api/models/options/entreprises/entreprise";
import { Site } from "@/api/models/options/entreprises/site";
import { Region } from "@/api/models/options/entreprises/region";
import { Branche } from "@/api/models/options/entreprises/branche";
import { Secteur } from "@/api/models/options/entreprises/secteur";
import { Tag } from "@/api/models/options/entreprises/tag";
import { CategorieOrientation } from "@/api/models/dossiers/categorieOrientation";
import { DomaineNouveauSansDossier } from "@/api/models/dossiers/domaineNouveauSansDossier";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import { required } from "@validations";
import { BFormSelect, BFormSelectOption } from "bootstrap-vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import router from '@/router'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    SearchableVueSelect,
    RessifnetDateInput,
    BFormSelect,
    BFormSelectOption,
  },
})
export default class CreateNouveauSansDossier extends Vue {
  required = required;
  dateToday = (new Date().toLocaleString('fr-FR', { month: 'numeric', day: 'numeric', year: 'numeric' }))

  model: NouveauSansDossierCreateModel = {
    dateCreation : this.dateToday
  };

  listeEntreprises: Entreprise[] = [];
  totalCountEntreprise = 0;

  listeSites: Site[] = [];
  totalCountSite = 0;

  listeSecteurs: Secteur[] = [];
  totalCountSecteur = 0;

  listeBranches: Branche[] = [];
  totalCountBranche = 0;

  listeRegions: Region[] = [];
  totalCountRegion = 0;

  listeTags: Tag[] = [];
  totalCountTag = 0;

  brancheLibelle = "";
  regionLibelle = "";

  listeCategories: CategorieOrientation[] = [];
  listeDomaines: DomaineNouveauSansDossier[] = [];

  selectedCategorie: any = null;

  totalCountCategorie = 0;
  totalCountDomaine = 0;

  updated = false;

  async created() {
    await this.loadCategories(null);
    await this.loadDomaines(null);

    router.beforeEach((to: any, from: any, next: any) => {
      if (this.updated && from.name == 'create-nouveau-sans-dossier') {
        this.$bvModal
          .msgBoxConfirm(
            "Etes-vous sûr de vouloir quitter cette page ? Vous avez commencé à remplir le formulaire, les données seront perdues.",
            {
              title: "Confirmation de perte des données",
              size: "sm",
              okVariant: "primary",
              okTitle: "Oui",
              cancelTitle: "Non",
              cancelVariant: "outline-secondary",
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then(async (value) => {
            if (value === true) {
              this.updated = false
              next()
            } else {
              next(false)
            }
          }); 
      } else {
        next()
      }
    })
  }

  @Watch('model')
  modelUpdated() {
    this.updated = true;
  }

  async save() {
    this.model.categorieId = this.selectedCategorie.id
    await this.$http.ressifnet.nouveauSansDossiers.post(this.model)
      .then(
        async (response: any) => {
          this.$swal({
            title: `Numéro d'ordre attribué : ${response}`,
            text: `Vous avez créé une action sans dossier avec succès !`,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success',
            },
            buttonsStyling: false,
            confirmButtonText: 'OK',
          })

          this.model = {};
          (this.$refs['formValidation'] as any).reset()
        }, 
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        })
  }

  async searchEntreprises(params: any) {
    if (!params || params?.reset) {
      this.listeEntreprises = this.listeEntreprises?.filter(e => e.id == this.model.entrepriseId);
      this.totalCountEntreprise = this.listeEntreprises.length;
    }

    this.onClearSite();
    this.onClearTag();
    this.onClearRegion();
    this.onClearBranche();
    await this.$http.ressifnet.entreprises
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((res: PaginatedList<Entreprise>) => {
        this.totalCountEntreprise = res.totalCount;
        this.listeEntreprises = this.listeEntreprises!.concat(
          res.items.filter(
            (ri) => !this.listeEntreprises!.some((lsi) => lsi.id == ri.id)
          )
        );
        // this.model.entrepriseId = this.listeEntreprises.length === 1 ? this.listeEntreprises[0].id : undefined;
        // if(this.model.entrepriseId != undefined) this.searchSecteurs(null);
      });
  }

  async searchSites(params: any) {
    if (!params || params?.reset) {
      this.listeSites = this.listeSites?.filter(e => e.id == this.model.siteId);
      this.totalCountSite = this.listeSites.length;
    }

    await this.$http.ressifnet.sites
      .paginatedList(
        this.model.entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
        "",
        "",
        this.model.regionId,
        this.model.brancheId
      )
      .then((res: PaginatedList<Site>) => {
        this.totalCountSite = res.totalCount;
        this.listeSites = this.listeSites!.concat(
          res.items.filter(
            (ri) => !this.listeSites!.some((lsi) => lsi.id == ri.id)
          )
        );
      });
  }

  async searchSecteurs(params: any) {
    if (!params || params?.reset) {
      this.listeSecteurs = this.listeSecteurs?.filter(e => e.id == this.model.secteurId);
      this.totalCountSecteur = this.listeSecteurs.length;
    }

    await this.$http.ressifnet.secteurs
      .paginatedList(
        this.model.entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
        "",
        this.model.siteId ?? "",
        this.model.regionId ?? "",
        this.model.brancheId ?? ""
      )
      .then((res: PaginatedList<Secteur>) => {
        this.totalCountSite = res.totalCount;
        this.listeSecteurs = this.listeSecteurs!.concat(
          res
            .items
            .filter(
              (ri) => !this.listeSecteurs!.some((lsi) => lsi.id == ri.id)
            )
            .map(x => <Secteur>{
              ...x,
              completeLabel: `${x.libelle} (${x.siteLibelle})`
            })
        );
        // this.model.secteurId = this.listeSecteurs.length === 1 ? this.listeSecteurs[0].id : undefined;
      });
  }

  async searchTags(params: any) {
    if (!params || params?.reset) {
      this.listeTags = this.listeTags.filter(e => this.model.tagsId?.some(id => id == e.id));
      this.totalCountTag = this.listeTags.length;
    }

    await this.$http.ressifnet.tags
      .paginatedList(this.model.entrepriseId, params?.pageNumber ?? 1, params?.pageSize ?? 10, params?.search)
      .then((res: PaginatedList<Tag>) => {
        this.totalCountTag = res.totalCount
        this.listeTags = this.listeTags!.concat(res.items.filter(ri => !this.listeTags!.some(lsi => lsi.id == ri.id)))
      }) 
  }

  async searchBranches(params: any) {
    if (!params || params?.reset) {
      this.listeBranches = this.listeBranches?.filter(e => e.id == this.model.brancheId);
      this.totalCountBranche = this.listeBranches.length;
    }

    await this.$http.ressifnet.branches
      .paginatedList(
        this.model.entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search
      )
      .then((res: PaginatedList<Branche>) => {
        this.totalCountBranche = res.totalCount;
        this.listeBranches = this.listeBranches!.concat(
          res.items.filter(
            (ri) => !this.listeBranches!.some((lsi) => lsi.id == ri.id)
          )
        );
      });
  }

  async searchRegions(params: any) {
    if (!params || params?.reset) {
      this.listeRegions = this.listeRegions?.filter(e => e.id == this.model.regionId);
      this.totalCountRegion = this.listeRegions.length;
    }

    await this.$http.ressifnet.regions
      .paginatedList(
        this.model.entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search
      )
      .then((res: PaginatedList<Region>) => {
        this.totalCountRegion = res.totalCount;
        this.listeRegions = this.listeRegions!.concat(
          res.items.filter(
            (ri) => !this.listeRegions!.some((lsi) => lsi.id == ri.id)
          )
        );
      });
  }

  async loadCategories(params: any) {
    await this.$http.ressifnet.categoriesOrientations
      .paginatedList(params?.pageNumber ?? 1, params?.pageSize ?? 100, true)
      .then((res: PaginatedList<CategorieOrientation>) => {
        this.totalCountCategorie = res.totalCount;
        this.listeCategories = res.items;
        // this.selectedCategorie = this.listeCategories.length === 1 ? this.listeCategories[0] : null;
      });
  }

  async loadDomaines(params: any) {
    await this.$http.ressifnet.domainesNouveauSansDossier
      .paginatedList(params?.pageNumber ?? 1, params?.pageSize ?? 100, true)
      .then((res: PaginatedList<DomaineNouveauSansDossier>) => {
        this.totalCountDomaine = res.totalCount;
        this.listeDomaines = res.items;
        // this.model.domaineId = this.listeDomaines.length === 1 ? this.listeDomaines[0].id : undefined;
      });
  }

  onEntrepriseSelected(val: any) {
    if (val) {
      const entreprise = this.listeEntreprises?.filter((s) => s.id == val)[0];
      this.model.siteId = undefined;
      this.listeSites = [];
      this.brancheLibelle =
        entreprise?.site?.brancheLibelle ?? "Ce site n'a pas de branche";
      this.regionLibelle =
        entreprise?.site?.regionLibelle ?? "Ce site n'a pas de région";
    }
  }

  onSiteSelected(val: any) {
    if (val) {
      const site = this.listeSites?.filter((s) => s.id == val)[0];
      this.model.brancheId = undefined;
      this.brancheLibelle =
        site?.brancheLibelle ?? "Ce site n'a pas de branche";
      this.regionLibelle = site?.regionLibelle ?? "Ce site n'a pas de région";
    }
  }

  onClearEntreprise() {
    this.model.entrepriseId = undefined;
    this.searchSecteurs(null);
    this.searchEntreprises(null);
    this.searchTags(null);
  }

  onClearSite() {
    this.searchSites(null);
    this.searchBranches(null);
    this.searchRegions(null);
    this.searchSecteurs(null);
  }

  onClearRegion() {
    this.searchRegions(null);
    this.searchSites(null);
  }

  onClearBranche() {
    this.searchBranches(null);
    this.searchSites(null);
  }

  onClearTag() {
    this.searchTags(null);
  }

  resetForm() {}
}
